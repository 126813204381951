<template>
  <img
      width="75%"
      style="
      max-width: 640px;
      width: 85%;
      "
      :src="require('../assets/img/2024-hongqi-h5-black.jpg')">
  <h1 class="text-h5 font-weight-bold mb-4 mt-2 text-center" >
    {{ carTitle }}
  </h1>
  <h2 class="text-h6 font-weight-thin mb-4 mt-2 text-center">
    <h1 class="text-h6 font-weight-thin mb-4 mt-6 text-justify">
      {{ carServiceText }} <b> {{ carPriceTransfer }} ₽</b>
    </h1>
    <h1 v-if="isCarAddInfo" class="text-subtitle-2 font-weight-thin mb-4 mt-6 text-justify">
      {{ carAddInfo }}
    </h1>
    <h1 class="text-h6 font-weight-thin mb-4 mt-6 text-justify">
      Стоимость часа аренды: <b> {{ carPriceRent }}  ₽</b>
      <br>
      Минимальный заказ: 3 часа
    </h1>
    <h1 class="text-h6 font-weight-thin mb-4 mt-6 text-justify">
      Фото автомобиля по запросу
    </h1>
  </h2>
  <form-call-to-rent-auto style="margin-bottom: 120px;"/>
</template>

<script>
import FormCallToRentAuto from "@/components/formCallToRentAuto";
export default {
  name: "vCar6.vue",
  components: {
    FormCallToRentAuto
  },
  data () {
    return {
      carServiceText: null,
      carTitle: null,
      carPriceTransfer: null,
      carPriceRent: null,
      carAddInfo:null,
      isCarAddInfo:null,
      selected : 'Hongqi H5 Deluxe черный',
      carouselHeight : "0px",
      picHeight: "100%",
      windowHeight: window.innerHeight,
      heightD : 0,
      slides: [
        {title: 'Hongqi H5 2024 г.в. черный',},
      ],

      items: [
        {src: require("../assets/img/7bl_1.jpg")},
        {src: require("../assets/img/7bl_2.jpg")},
        {src: require("../assets/img/7bl_3.jpg")},
        {src: require("../assets/img/7bl_4.jpg")},
        {src: require("../assets/img/7bl_5.jpg")}
      ],
    }
  },
  methods:{
    getCarouselHeight () {
      if (this.windowHeight > 901 ) {
        this.carouselHeight = "900px"

      }
      if (this.windowHeight < 901) {
        //this.carouselHeight = 300
        //this.picHeight = 300

      }
    },
    getCarProps(){
      this.carServiceText = this.$parent.carServiceText;
      this.carPriceArr = this.$parent.carPrice;
      this.carTitle = this.carPriceArr[5].title;
      this.carPriceTransfer = this.carPriceArr[5].costTransfer;
      this.carPriceRent = this.carPriceArr[5].costRent;
      this.carAddInfo = this.carPriceArr[5].addInfo;
      if (this.carAddInfo != null) {
        this.isCarAddInfo = true;
      }

    }
  },
  mounted() {
    this.getCarProps();
    this.getCarouselHeight();
    if (this.windowHeight > 901 ) {
      this.carouselHeight = 900
      return this.carouselHeight
    }
    if (this.windowHeight < 901) {
      this.carouselHeight = 300
      return this.carouselHeight
    }
  }
}
</script>

<style scoped>

</style>