<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        width="100%"
    >
      <template v-slot:activator="{ props }">
        <v-btn v-show="mobile" color="black" v-bind="props" v-on:click="changeSelectForm()" density="default" class="text-white mt-4 mb-4">Заказать</v-btn>
        <v-btn v-show="desc1080 || desc1600" color="black" v-on:click="changeSelectForm()" height="75" width="150" v-bind="props" size="x-large" density="default" class="text-white mt-4 mb-4">Заказать</v-btn>

      </template>
      <v-card :class="this.styleDialog">
        <v-form @submit.prevent="submitComment">
          <v-card-text>
            <v-container>

              <v-row>
                <v-col cols="12" >
                  <span class="text-h6">Аренда авто с водителем</span>
                  <v-alert
                      closable
                      title="Ошибка"
                      text="Что-то пошло не так. Повторите запрос"
                      type="error"
                      v-if="showAlert"
                  ></v-alert>
                  <v-alert
                      closable
                      title="Ваша заявка принята"
                      text="Уже работаем над Вашим запросом"
                      type="success"
                      v-if="showSuccess"
                  ></v-alert>
                  <v-text-field class="mt-2 mb-2"
                                label="Ваше имя*"
                                required
                                variant="outlined"
                                v-model="callToTaxiForm.firstName"
                                :rules="[rules.required]"

                  ></v-text-field>
                  <v-text-field class="mt-2 mb-2"
                                label="Телефон*"
                                variant="outlined"
                                v-model="callToTaxiForm.phoneNumber"
                                :rules="[rules.required, rules.phone, rules.counter]"
                                @update:modelValue="setPhone"
                                v-on:keypress="NumbersOnly"

                  ></v-text-field>
                  <v-select
                      disabled="true"
                      v-model="callToTaxiForm.selected"
                      :rules="[rules.required]"
                      :items="['BMW 5 series', 'BMW 5 series рестайлинг', 'BMW 5 series Белая','BMW 7 series Long','BMW 7 series Long Black','MERCEDES E Class','Hongqi H5 Deluxe','Hongqi H9']"
                      label="Какой авто?*"
                      variant="outlined"
                      required
                      @update:modelValue="onChangeSelect"
                  ></v-select>
                  <v-select
                      v-model="callToTaxiForm.selectedTypeRide"
                      :rules="[rules.required]"
                      :items="['Трансфер', 'Аренда с водителем']"
                      label="Какой тип заказа?*"
                      variant="outlined"
                      required
                      @update:modelValue="onChangeSelectTypeRide"
                  ></v-select>
                  <span class="text-caption" v-if="isRent">Продолжительность аренды</span>
                  <v-slider
                      v-if="isRent"
                      v-model="slider"
                      class="align-center"
                      :max="max"
                      :min="min"
                      :step="1"
                      @update:modelValue="setCost"
                      hide-details
                  >
                    <template v-slot:append>
                      <v-text-field
                          v-model="slider"
                          hide-details
                          single-line
                          density="compact"
                          variant="outlined"
                          type="number"
                          style="width: 67px;"
                          @update:modelValue="setNormal"
                          @update:focused="setNormal2"
                      ></v-text-field>
                    </template>
                  </v-slider>
                  <span class="text-caption ">Дата и время</span>
                 <span class="text-caption text-red" v-if="dateEmpty"> Не заполнено</span>
                  <VueDatePicker class="mt-2" :rules="[rules.required]" v-model="date" :format="format" locale="ru" select-text="Ок" cancel-text="Закр" teleport-center  @update:model-value="pickDate"/>
                  <span v-if="isRent" class="text-caption ">Предв. стоимость:<span class="text-overline ml-4">{{ costRent }} руб.</span></span>
                  <span v-if="isTransfer" class="text-caption ">Предв. стоимость от:<span class="text-overline ml-4">{{ costRentTransf }} руб.</span></span>
                </v-col>
              </v-row>
            </v-container>
            <small>*Обязательно для заполнения</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red"
                variant="outlined"
                @click="dialog = false"
                class="mr-5"
                v-on:click="clearCallForm"
            >
              Закрыть
            </v-btn>
            <v-btn
                type="submit"
                color="green"
                variant="outlined"
            >
              Оформить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
require('@vuepic/vue-datepicker/dist/main.css')
import axios from 'axios';
import { ref } from 'vue';
export default {
  name: "formCallToRentAuto.vue",
  components:{
    VueDatePicker,
  },
   setup() {
    const date = ref(new Date());

    const format = (date) => {
      const dateTime = date.toLocaleString('ru-RU', {dateStyle: 'short', timeStyle: 'short'})
      return dateTime
    }

    return {
      date,
      format
    }
  },
  data() {
    return{

      callToTaxiForm: {
        firstName: null,
        phoneNumber: '+7',
        time: null,
        date: new Date(),
        cost: null,
        reqType: 2,
        selectedTypeRide: null,

      },
      costRent : null,
      costRentTransf: null,
      styleSlider : null,
      min: 3,
      max: 24,
      slider: 3,
      showAlert : false,
      showSuccess: false,
      loading: false,
      timeEmpty: false,
      dateEmpty: false,
      selected: null,
      isRent : false,
      isTransfer: false,

      isHidden: true,
      windowHeight: window.innerHeight,
      dialog: false,
      desc1080 : false,
      desc1600 : false,
      mobile : false,
      styleDialog : null,
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
      rules: {
        required: value => !!value || 'Не заполнено поле!',
        counter: value => value.length <= 12 || 'Номер должен быть не более 12 символов!',
        phone: value => {
          const pattern = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
          return pattern.test(value) || 'Неправильный номер!'
        },
      },
    }

  },
  methods: {
    changeSelectForm(){
    //this.callToTaxiForm.selected = this.$parent.selected;
      this.callToTaxiForm.selected = this.$parent.carTitle;
      this.date = new Date();
      this.callToTaxiForm.date = this.date.toLocaleString('ru-RU', {dateStyle: 'short', timeStyle: 'short'})
    //this.setCost();
    },
    onChangeSelectTypeRide(){
      if (this.callToTaxiForm.selectedTypeRide == 'Аренда с водителем') {
        this.callToTaxiForm.typeReq = 'Rent'
        this.isRent = true
        this.isTransfer = false
        this.setCost();
      }
      if (this.callToTaxiForm.selectedTypeRide == 'Трансфер') {
        this.callToTaxiForm.typeReq = 'Transf'
        this.isTransfer = true
        this.isRent = false
        this.slider = 3;
        this.setCostTransf();
      }
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true;
      }
    },
    setPhone(){
      if (this.callToTaxiForm.phoneNumber === '+') {
        this.callToTaxiForm.phoneNumber = '+7'
      }
      if (this.callToTaxiForm.phoneNumber === '7') {
        this.callToTaxiForm.phoneNumber = '+7'
      }
      if (this.callToTaxiForm.phoneNumber === '+789') {
        this.callToTaxiForm.phoneNumber = '+79'
      }
      if (this.callToTaxiForm.phoneNumber.length > 12) {
        this.callToTaxiForm.phoneNumber = this.callToTaxiForm.phoneNumber.substr(0, 12)
      }
    },
    clearCallForm(){
      this.callToTaxiForm.firstName = null
      this.callToTaxiForm.date = null
      this.callToTaxiForm.time = null
      this.callToTaxiForm.phoneNumber = '+7'
      this.callToTaxiForm.selectedTypeRide = null
      this.showAlert = false;
      this.showSuccess = false;
      this.slider = 3;
      this.isRent = false;
      this.isTransfer = false;

    },
    doTextNormal(){
      if (this.windowHeight > 901 && this.windowHeight < 1300) {
        this.desc1080 = true
        this.styleSlider = 'm';
        this.styleDialog = 'dialogDesk'
        //alert(this.textH)
      }
      if (this.windowHeight > 1300) {
        this.desc1600 =
            this.styleSlider = 'l';
        this.styleDialog = 'dialogDesk'
        //alert(this.textH)
      }
      if (this.windowHeight < 901 ) {
        this.mobile = true
        this.styleSlider = 's';
        this.styleDialog = ''
        //alert(this.textH)
      }
    },
    onChangeSelect(){
      this.setCost()
      this.callToTaxiForm.time = null
      this.callToTaxiForm.date = null
      this.date = null
    },
    pickTime(){
      this.timeEmpty = false;
    },
    pickDate(){
      this.dateEmpty = false;
      if(this.date != null) {
        this.callToTaxiForm.date = this.date.toLocaleString('ru-RU', {dateStyle: 'short', timeStyle: 'short'})
      }
    },
    setNormal2() {
      if (this.slider < 3) this.slider = 3
      if (this.slider > 24) this.slider = 24
      this.setCost()
    },
    setNormal(){
      this.setCost()
    },
    setCost(){
      this.costRent = this.slider * this.$parent.carPriceRent;
      // this.callToTaxiForm.selected = this.$parent.costRent;
      // if (this.callToTaxiForm.selected == 'BMW 5 series') {
      //   this.costRent = this.slider * 1800
      // }
      // if (this.callToTaxiForm.selected == 'BMW 5 series рестайлинг') {
      //   this.costRent = this.slider * 1900
      // }
      // if (this.callToTaxiForm.selected == 'BMW 5 series Белая') {
      //   this.costRent = this.slider * 2100
      // }
      // if (this.callToTaxiForm.selected == 'BMW 5 series рестайлинг Белая') {
      //   this.costRent = this.slider * 2200
      // }
      // if (this.callToTaxiForm.selected == 'BMW 7 series Long') {
      //   this.costRent = this.slider * 2900
      // }
      // if (this.callToTaxiForm.selected == 'BMW 7 series Long Black') {
      //   this.costRent = this.slider * 2900
      // }
      // if (this.callToTaxiForm.selected == 'MERCEDES E Class') {
      //   this.costRent = this.slider * 1900
      // }
      // if (this.callToTaxiForm.selected == 'Hongqi H5 Deluxe') {
      //   this.costRent = this.slider * 2100
      // }
      // if (this.callToTaxiForm.selected == 'Hongqi H9') {
      //   this.costRent = this.slider * 2900
      // }
      // if (this.callToTaxiForm.selected == 'MERCEDES Maybach') {
      //   this.costRent = this.slider * 3900
      // }
    },
    setCostTransf(){
      this.costRentTransf = this.$parent.carPriceTransfer;
      // if (this.callToTaxiForm.selected == 'BMW 5 series') {
      //   this.costRentTransf =  2000
      // }
      // if (this.callToTaxiForm.selected == 'BMW 5 series рестайлинг') {
      //   this.costRentTransf = 2000
      // }
      // if (this.callToTaxiForm.selected == 'BMW 5 series Белая') {
      //   this.costRentTransf = 2000
      // }
      // if (this.callToTaxiForm.selected == 'BMW 5 series рестайлинг Белая') {
      //   this.costRentTransf = 2000
      // }
      // if (this.callToTaxiForm.selected == 'MERCEDES E Class') {
      //   this.costRentTransf = 2000
      // }
      // if (this.callToTaxiForm.selected == 'Hongqi H5 Deluxe') {
      //   this.costRentTransf = 2000
      // }
      // if (this.callToTaxiForm.selected == 'Hongqi H9') {
      //   this.costRentTransf = 4000
      // }
      // if (this.callToTaxiForm.selected == 'BMW 7 series Long') {
      //   this.costRentTransf = 4000
      // }
      // if (this.callToTaxiForm.selected == 'BMW 7 series Long Black') {
      //   this.costRentTransf = 4000
      // }
      // if (this.callToTaxiForm.selected == 'MERCEDES Maybach') {
      //   this.costRentTransf = 7000
      // }
    },
    submitComment() {
      this.showAlert = false;
      if (!this.callToTaxiForm.date) {
        this.dateEmpty = true
      }
      if (!this.callToTaxiForm.firstName || !this.callToTaxiForm.phoneNumber || !this.callToTaxiForm.selected || !this.callToTaxiForm.date) return false
      if (this.callToTaxiForm.selectedTypeRide == 'Аренда с водителем') {
        this.callToTaxiForm.time = this.slider
        this.callToTaxiForm.cost = this.costRent
      }
      if (this.callToTaxiForm.selectedTypeRide == 'Трансфер') {
        this.callToTaxiForm.time = null
        this.callToTaxiForm.cost = this.costRentTransf
      }
      axios.post('https://drivep.ru/back/send_tg.php', this.callToTaxiForm)
          .then(response => {
            if (JSON.stringify(response.data.ok) != 'true') {
              this.showAlert = true
            } else {
              this.showSuccess = true
              setTimeout(() =>  this.dialog = false, 4000)
              setTimeout(() =>  this.showSuccess = false, 5000)
              setTimeout(() => this.clearCallForm(), 5000)
            }
          })
          .catch(error => {
            this.showAlert = true
            console.log(error)
          })
    }
  },
  mounted() {
    this.doTextNormal();
  }
}
</script>

<style scoped>
.m{
  width: 60px;
}
.l{
  width: 75px;
}
.s{
  width: 50px;
}
.dialogDesk{
  width: 25%; margin: auto;
}
</style>