<template>
  <v-layout class="rounded rounded-md">
    <NavBar/>
   <MainContent/>
  </v-layout>
</template>

<script>
import NavBar from './components/NavBar.vue'
import MainContent from './components/MainContent'

export default {
  name: 'App',

  components: {
      NavBar,
    MainContent,
  },

  data: () => ({
  }),
}
</script>
