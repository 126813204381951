<template>
   <v-carousel :show-arrows="false"
              hide-delimiter-background
              min-width = "358px"
              height=" {{ this.carouselHeight }}"
              color="white"
               class="mb-4"

  >
    <v-carousel-item
        v-for="(item,c) in items"
        :key="c"
        :src="item.src"
        cover
        :height="this.picHeight"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "vCarAll.vue",
  data () {
    return {
      carouselHeight : "0px",
      picHeight: "100%",
      windowHeight: window.innerHeight,
      heightD : 0,

      items: [
        {src: require("../assets/img/all_1.jpg")},
        {src: require("../assets/img/all_2.jpg")},
        {src: require("../assets/img/all_3.jpg")},
        {src: require("../assets/img/all_4.jpg")},
        {src: require("../assets/img/all_5.jpg")},
      ],
    }
  },
  methods:{
    getCarouselHeight () {
      if (this.windowHeight > 901 ) {
        this.carouselHeight = "900px"

      }
      if (this.windowHeight < 901) {
        this.carouselHeight = 300
        this.picHeight = 300

      }
    }
  },
  mounted() {
    this.getCarouselHeight();
    if (this.windowHeight > 901 ) {
      this.carouselHeight = 900
      return this.carouselHeight
    }
    if (this.windowHeight < 901) {
      this.carouselHeight = 300
      return this.carouselHeight
    }
  }
}
</script>

<style scoped>

</style>