<template>

  <v-app-bar
      :elevation="2"
      color="black"
  >
    <v-btn icon v-on:click="goZvonilka">
      <v-icon>mdi-phone</v-icon>
    </v-btn>
       <v-toolbar-title >ДрайвПитер</v-toolbar-title>
    <v-btn icon v-on:click="goTelega">
      <v-icon>mdi-send</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar.vue",
  methods: {
    goTelega() {
      window.location.href = 'https://t.me/euphoriabrul'
    },
    goZvonilka() {
      window.location.href = 'tel:+79214107977'
    }
  }
}
</script>

<style scoped>

</style>