<template>
  <h1 class="text-h5 font-weight-bold mb-4 mt-2 text-center">
  Бизнес класс
  </h1>
      <v-car />
      <v-car2/>
      <v-car3/>
      <v-car4/>
      <v-car5/>
      <v-car6/>
      <v-car7/>
  <h1 class="text-h5 font-weight-bold mb-4 mt-2 text-center">
    Представительский класс
  </h1>
<!--      <v-car8/>-->
      <v-car9/>
      <v-car10/>
  <h1 class="text-h5 font-weight-bold mb-4 mt-2 text-center">
    Mercedes Maybach
  </h1>
      <v-car11/>
</template>

<script>
import vCar from '@/components/vCar'
import vCar2 from '@/components/vCar2'
import vCar3 from '@/components/vCar3'
import vCar4 from '@/components/vCar4'
import vCar5 from '@/components/vCar5'
import vCar6 from '@/components/vCar6'
import vCar7 from '@/components/vCar7'
// import vCar8 from '@/components/vCar8'
import vCar9 from '@/components/vCar9'
import vCar10 from '@/components/vCar10'
import vCar11 from '@/components/vCar11'
export default {
  name: "SlideShow.vue",
  components: {
    vCar,
    vCar2,
    vCar3,
    vCar4,
    vCar5,
    vCar6,
    vCar7,
    // vCar8,
    vCar9,
    vCar10,
    vCar11,
  },
    data () {
    return {
      carServiceText : 'Трансфер аэропорт/город – город/аэропорт: от ',
      carPrice: [
        {
          title: 'BMW 5 series 2020 г.в. черный',
          costRent: 1800+300,
          costTransfer: 2000,
          addInfo: '* если необходима встреча с табличкой + 200 ₽'
        },
        {
          title: 'BMW 5 series 2020 г.в. белый',
          costRent: 1900+200,
          costTransfer: 2000,
          addInfo: '* если необходима встреча с табличкой + 200 ₽'
        },
        {
          title: 'BMW 5 series 2021 г.в. черный рестайлинг',
          costRent: 2100+200,
          costTransfer: 2000,
          addInfo: '* если необходима встреча с табличкой + 200 ₽'
        },
        {
          title: 'BMW 5 series 2021 г.в. белый рестайлинг',
          costRent: 2100+200,
          costTransfer: 2000,
          addInfo: '* если необходима встреча с табличкой + 200 ₽'
        },
        {
          title: 'Mercedes-Benz E-Class 2020 г.в. черный',
          costRent: 1900+200,
          costTransfer: 2000,
          addInfo: '* если необходима встреча с табличкой + 200 ₽'
        },
        {
          title: 'Hongqi H5 2024 г.в. черный',
          costRent: 2100+200,
          costTransfer: 2000,
          addInfo: '* если необходима встреча с табличкой + 200 ₽'
        },
        {
          title: 'Hongqi H5 2024 г.в. белый',
          costRent: 2100+200,
          costTransfer: 2000,
          addInfo: '* если необходима встреча с табличкой + 200 ₽'
        },
        {
          title: 'BMW 7 series 2020 г.в. черный',
          costRent: 2900+200,
          costTransfer: 4000
        },
        {
          title: 'BMW 7 series Long 2020 г.в. черный',
          costRent: 2900+200,
          costTransfer: 4000,
        },
        {
          title: 'Hongqi H9 2024 г.в. черный',
          costRent: 2900+200,
          costTransfer: 4000
        },
        {
          title: 'Mercedes-Benz Maybach 2020 г.в. черный',
          costRent: 3500+1000,
          costTransfer: 7000
        },
      ],

    }
  },
  methods:{

  },
  mounted() {
    }
}
</script>

<style scoped>

</style>