<template>
  <v-main class="d-flex align-center justify-center" style="min-height: 300px;">

    <v-container class="bg-surface-variant">
      <v-row no-gutters>
        <v-col>
          <v-sheet >

            <v-parallax
                class="parlax1"
                scale = "0.95"

                src="../assets/img/main_foto.jpg"
            >
              <div class="d-flex flex-column fill-height justify-center align-center text-black">
                <h3 v-show="mobile" class=" text-h5 font-weight-medium mb-4 text-center mt-3" >
                  Закажи автомобиль прямо сейчас!
                </h3>
                <h1 v-show="desc1080 || desc1600" class="text-lg-h1 font-weight-medium mb-4  text-center" :class="this.screenSize" >
                  Закажи автомобиль прямо сейчас!
                </h1>
                <h4 class="subheading">

                </h4>

                <formCallToTaxi />
              </div>
            </v-parallax>

          </v-sheet>
        </v-col>



        <v-responsive width="100%"></v-responsive>

        <v-col class="mt-10">
          <v-sheet class="pa-2 ma-2 mt-10">

            <div class="d-flex flex-column fill-height justify-center align-center text-black">
              <h1 v-show="mobile" class="text-h4 font-weight-bold mb-4 mt-10 text-center">
                Аренда автомобиля с водителем
              </h1>
              <h1 v-show="desc1080 || desc1600" class="text-h3 font-weight-bold mb-4 mt-10 text-center">
                Аренда автомобиля с водителем
              </h1>
              <SlideShow/>
            </div>

<!--            <div class="d-flex flex-column fill-height justify-center align-center text-black">-->
<!--              <h1 v-show="mobile" class="text-h5 font-weight-medium mb-4 text-center">-->
<!--                Аренда автомобиля с водителем-->
<!--              </h1>-->
<!--              <h1 v-show="desc1080 || desc1600" class="text-h3 font-weight-medium mb-4 text-center">-->
<!--                Аренда автомобиля с водителем-->
<!--              </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--                BMW 5 ser. - 1800 руб./час-->
<!--              </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--                BMW 5 ser. Белая - 1900 руб./час-->
<!--              </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--                BMW 5 ser. (рест.) - 2100руб./час-->
<!--              </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--                MERCEDES E Class - 1900 руб./час-->
<!--              </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--                BMW 7 ser. Long - 2900 руб./час-->
<!--              </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--                BMW 7 ser. Long Black - 2900 руб./час-->
<!--              </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--              Hongqi H5 Deluxe - 2100руб./час-->
<!--              </h1>-->
<!--                <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--              Hongqi H9 - 2900 руб./час-->
<!--                </h1>-->
<!--              <h1 class="text-h6 font-weight-light mb-4 text-center">-->
<!--                (минимальный заказ от 3х часов)-->
<!--              </h1>-->
<!--              <form-call-to-rent-auto />-->
<!--            </div>-->

          </v-sheet>
        </v-col>

        <v-responsive width="100%"></v-responsive>

        <v-col class="mt-10">
          <v-sheet >

            <v-parallax
                class="parlax2"
                scale = "0.9"
                src="../assets/img/IM_PD.jpg"
            >
              <div class="d-flex flex-column fill-height justify-center align-center text-white">
                <h1 class="text-h4 font-weight-bold mb-4 banner" :class="this.screenSize">
                  Личный водитель
                </h1>

                <h4 v-show="mobile"  class="subheading text-center">
                  Доверьте свою безопасность профессионалам!
                </h4>
                <h1 v-show="desc1080 || desc1600"  class="subheading text-center" style="font-size: 2.5em;">
                  Доверьте свою безопасность профессионалам!
                </h1>
                <form-call-to-pers-driver/>
              </div>
            </v-parallax>

          </v-sheet>
        </v-col>

        <v-responsive width="100%"></v-responsive>

        <v-col class="mt-10">
          <v-sheet class="pa-2 ma-2">



          </v-sheet>
        </v-col>

        <v-responsive width="100%"></v-responsive>

        <v-col class="mt-10">
          <v-sheet class="pa-2 ma-2">

            <div class="d-flex flex-column fill-height justify-center align-center text-black">
              <h1 v-show="mobile" class="text-h5 font-weight-bold mb-4 text-center">
                Наше предложение
              </h1>
              <h1 v-show="desc1080 || desc1600" class="text-h3 font-weight-bold mb-4 text-center">
                Наше предложение
              </h1>
              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Мы перевозим и сопровождаем детей в школу\садик и т.п., водители имеют опыт работы и соблюдают все правила и стандарты перевозки детей. </h1>
              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Транспорт на свадебные мероприятия, личный водитель в семью. Работа на торжественных мероприятиях разного уровня. </h1>
              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Автомобиль представительского класса, который всегда будет под рукой. Вам не придется тратить время на ожидание такси или мириться с дискомфортом общественного транспорта. Любые маршруты на Ваш выбор. </h1>
              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Наш транспорт – наша ответственность. За любое недоразумение, связанное с арендованной машиной, отвечает не клиент, а водитель. </h1>
              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Личный водитель – в вашем распоряжении. Он не только отвезет вас по указанному адресу, но также поможет с вопросами загрузки и выгрузки багажа. </h1>
              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Мы предлагаем на свои услуги разумные цены. И это при сервисе высочайшего уровня. </h1>

              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Оплата производится любым удобным для вас способом.<br>
                Работаем с физическими и юридическими лицами. </h1>
                <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Для расчета Вашей поездки оставляйте заявку или звоните по телефону <span v-on:click="goZvonilka">+79214107977</span>,<br> С уважением Кристина.
              </h1>
             </div>

          </v-sheet>
        </v-col>

        <v-responsive width="100%"></v-responsive>

        <v-col class="mt-8">
          <v-sheet class="pa-2 ma-2">

            <div class="d-flex flex-column fill-height justify-center align-center text-black">
              <h1 v-show="mobile" class="text-h5 font-weight-bold mb-4 text-center">
                О компании
              </h1>
              <h1 v-show="desc1080 || desc1600" class="text-h3 font-weight-bold mb-4 text-center">
                О компании
              </h1>
              <vCarAll/>
              <h1 class="text-h6 font-weight-thin mb-4 mt-6 text-justify">
                На рынке пассажирских перевозок с 2015 года.
                Более 40 автомобилей бизнес класса на любой вкус.<br><br>
                Водители - профессионалы своего дела со стажем от 10 лет.
                Максимально низкие цены и высокий уровень сервиса.
              </h1>
              <h1 class="text-h6 font-weight-thin mb-4 text-center">
                Индивидуальный подход к каждому заказчику.
              </h1>

              </div>

          </v-sheet>
        </v-col>

        <v-responsive width="100%"></v-responsive>

        <v-col cols="12" sm="4" class="text-center">
          <span class="ml-4 text-black text-overline"> +7 (921) 410-79-77</span>
        </v-col>
        <v-col cols="12" sm="4" class="text-center">
          <span class="ml-4 text-black text-overline">СПБ, БЦ ЭКО, СТАРОДЕРЕВЕНСКАЯ УЛ. 11 К.2.ОФИС 430</span>
        </v-col>
        <v-col cols="12" sm="4" class="text-center">
          <span class="text-black text-overline">drivepiter@ya.ru</span>
        </v-col>
<!--        <v-col>-->
<!--          <v-sheet class="pa-2 ma-2">-->
<!--            .v-col-auto-->
<!--          </v-sheet>-->
<!--        </v-col>-->
      </v-row>


      <v-footer>
        <v-row justify="center" no-gutters>
          <v-btn
              v-for="link in links"
              :key="link"
              color="white"
              variant="text"
              class="mx-2"
              rounded="xl"
          >
            {{ link }}
          </v-btn>

          <v-col class="text-center mt-4" cols="12">
            {{ new Date().getFullYear() }} — <strong>ДрайвПитер</strong>
          </v-col>
        </v-row>
      </v-footer>
    </v-container>


  </v-main>



</template>

<script>
import SlideShow from "@/components/SlideShow";
import formCallToTaxi from "@/components/formCallToTaxi";
import vCarAll from "@/components/vCarAll";
//import FormCallToRentAuto from "@/components/formCallToRentAuto";
import FormCallToPersDriver from "@/components/formCallToPersDriver"
export default {
  name: "MainContent.vue",
components: {
  FormCallToPersDriver,
  //FormCallToRentAuto,
  formCallToTaxi,
  SlideShow,
  vCarAll,
},
  data ()  {
    return {
      windowHeight: window.innerHeight,
      mobile : false,
      desc1080 : false,
      desc1600 : false,
      screenSize : null,
      textH : "text-h5",
      offsetTop:0,
      goToOptions: {
        duration: 10000,
        offset: 0,
        easing: 'easeInOutCubic',
      },
    };
  },
  computed:{
    showGoToTop () {
      return this.offsetTop > 200;
    },
  },
  methods: {
    onScroll (event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;
    },
    goZvonilka() {
      window.location.href = 'tel:+79214107977'
    },
    goCompetition() {
      window.location.href = 'https://t.me/Draw_mercedes'
    },
    doTextNormal(){
      if (this.windowHeight > 901 && this.windowHeight < 1300) {
        this.desc1080 = true
        this.screenSize = 'desktop1k'
        //alert(this.textH)
      }
      if (this.windowHeight > 1300) {
        this.desc1600 = true
        this.screenSize = 'desktop2k'
        //alert(this.textH)
      }
      if (this.windowHeight < 901 ) {
        this.mobile = true
        //alert(this.textH)
      }
    }
  },
  mounted() {
    this.doTextNormal();
    if (this.windowHeight > 901 ) {
      this.carouselHeight = 900
      return this.carouselHeight
    }
    if (this.windowHeight < 901) {
      this.carouselHeight = 300
      return this.carouselHeight
    }
  }
}
</script>

<style scoped>
.bg-surface-variant{
  background-color: white !important;
}
.v-container {
  padding: 0px !important;
}
.parlax1{
  opacity: 0.9;
}
.parlax2{
  opacity: 0.85;
}
.banner {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 30px;
}
.banner_m {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
}
.desktop1k{
  margin-top: 250px;
  font-size: 8em !important;
  line-height: 8rem !important;
}
.desktop2k{
  margin-top: 500px;
  font-size: 12em !important;
  line-height: 12rem !important;
}
.competitionCSS{
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
}
</style>