<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        width="100%"
    >
      <template v-slot:activator="{ props }">
        <v-btn v-show="mobile" density="default" v-bind="props" class="text-black mt-4 mb-4">Отправить запрос</v-btn>
        <v-btn v-show="desc1080"  height="75" width="180" density="default" v-bind="props" class="text-black mt-4 mb-4">Отправить запрос</v-btn>
        <v-btn v-show="desc1600" style="height: 120px; width: 350px; font-size: 1.5em !important;" density="default" v-bind="props" class="text-black mt-4 mb-4">Отправить запрос</v-btn>
      </template>
      <v-card :class="this.styleDialog">
        <v-form @submit.prevent="submitComment">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" >
                  <span class="text-h7">Услуга Персональный водитель</span>
                  <v-alert
                      closable
                      title="Ошибка"
                      text="Что-то пошло не так. Повторите запрос"
                      type="error"
                      v-if="showAlert"
                  ></v-alert>
                  <v-alert
                      closable
                      title="Ваша заявка принята"
                      text="Уже работаем над Вашим запросом"
                      type="success"
                      v-if="showSuccess"
                  ></v-alert>
                  <v-text-field class="mt-2 mb-2"
                                label="Ваше имя*"
                                required
                                variant="outlined"
                                v-model="callToTaxiForm.firstName"
                                :rules="[rules.required]"

                  ></v-text-field>
                  <v-text-field class="mt-2 mb-2"
                                label="Телефон*"
                                variant="outlined"
                                v-model="callToTaxiForm.phoneNumber"
                                :rules="[rules.required, rules.phone, rules.counter]"
                                @update:modelValue="setPhone"
                                v-on:keypress="NumbersOnly"

                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Обязательно для заполнения</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red"
                variant="outlined"
                @click="dialog = false"
                class="mr-5"
                v-on:click="clearCallForm"
            >
              Закрыть
            </v-btn>
            <v-btn
                type="submit"
                color="green"
                variant="outlined"
            >
              Оформить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
require('@vuepic/vue-datepicker/dist/main.css')
import axios from 'axios';
export default {
  name: "formCallToPersDriver.vue",
  components:{

  },

  data() {
    return{

      callToTaxiForm: {
        firstName: null,
        phoneNumber: '+7',
        reqType: 3,

      },
      costRent : null,
      min: 3,
      max: 24,
      slider: 3,
      showAlert : false,
      showSuccess: false,
      loading: false,
      timeEmpty: false,
      dateEmpty: false,
      selected: null,
      isHidden: true,
      windowHeight: window.innerHeight,
      dialog: false,
      desc1080 : false,
      desc1600 : false,
      mobile : false,
      styleDialog : null,
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
      rules: {
        required: value => !!value || 'Не заполнено поле!',
        counter: value => value.length <= 12 || 'Номер должен быть не более 12 символов!',
        phone: value => {
          const pattern = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
          return pattern.test(value) || 'Неправильный номер!'
        },
      },
    }

  },
  methods: {
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true;
      }
    },
    setPhone(){
      if (this.callToTaxiForm.phoneNumber == '+') {
        this.callToTaxiForm.phoneNumber = '+7'
      }
      if (this.callToTaxiForm.phoneNumber == '7') {
        this.callToTaxiForm.phoneNumber = '+7'
      }
      if (this.callToTaxiForm.phoneNumber == '+789') {
        this.callToTaxiForm.phoneNumber = '+79'
      }
      if (this.callToTaxiForm.phoneNumber.length > 12) {
        this.callToTaxiForm.phoneNumber = this.callToTaxiForm.phoneNumber.substr(0, 12)
      }

    },
    clearCallForm(){
      this.callToTaxiForm.firstName = null
      this.callToTaxiForm.phoneNumber = '+7'
      this.showAlert = false
      this.showSuccess = false
    },
    doTextNormal(){
      if (this.windowHeight > 901 && this.windowHeight < 1300) {
        this.desc1080 = true
        this.styleDialog = 'dialogDesk'
        //alert(this.textH)
      }
      if (this.windowHeight > 1300) {
        this.desc1600 = true
        this.styleDialog = 'dialogDesk'
        //alert(this.textH)
      }
      if (this.windowHeight < 901 ) {
        this.mobile = true
        this.styleDialog = ''
        //alert(this.textH)
      }
    },
    onChangeSelect(){
      this.setCost()
      this.callToTaxiForm.time = null
      this.callToTaxiForm.date = null
    },
    pickTime(){
      this.timeEmpty = false
    },
    pickDate(){
      this.dateEmpty = false
    },
    submitComment() {
      this.showAlert = false
      if (!this.callToTaxiForm.firstName || !this.callToTaxiForm.phoneNumber) return false
      axios.post('https://drivep.ru/back/send_tg.php', this.callToTaxiForm)
          .then(response => {
            if (JSON.stringify(response.data.ok) != 'true') {
              this.showAlert = true
            } else {
              this.showSuccess = true
              setTimeout(() =>  this.dialog = false, 4000)
              setTimeout(() =>  this.showSuccess = false, 5000)
              setTimeout(() => this.clearCallForm(), 5000)
            }
          })
          .catch(error => {
            this.showAlert = true
            console.log(error)
          });
    }
  },
  mounted() {
    this.doTextNormal()
  }
}
</script>

<style scoped>
.dialogDesk{
  width: 25%; margin: auto;
}
</style>