<template>
  <img
      width="75%"
      style="
      max-width: 640px;
      width: 85%;
      "
      :src="require('../assets/img/2020-bmw-5-series-black.jpg')">
  <h1 class="text-h5 font-weight-bold mb-4 mt-2 text-center" >
         {{ carTitle }}
        </h1>
  <h2 class="text-h6 font-weight-thin mb-4 mt-2 text-center">
    <h1 class="text-h6 font-weight-thin mb-4 mt-6 text-justify">
      {{ carServiceText }} <b> {{ carPriceTransfer }} ₽</b>
    </h1>
      <h1 v-if="isCarAddInfo" class="text-subtitle-2 font-weight-thin mb-4 mt-6 text-justify">
        {{ carAddInfo }}
    </h1>
    <h1 class="text-h6 font-weight-thin mb-4 mt-6 text-justify">
    Стоимость часа аренды: <b> {{ carPriceRent }}  ₽</b>
      <br>
    Минимальный заказ: 3 часа
    </h1>
    <h1 class="text-h6 font-weight-thin mb-4 mt-6 text-justify">
      Фото автомобиля по запросу
    </h1>
  </h2>
  <form-call-to-rent-auto style="margin-bottom: 120px;"/>
<!--  <v-carousel :show-arrows="false"-->
<!--              hide-delimiter-background-->
<!--              min-width = "358px"-->
<!--              height=" {{ this.carouselHeight }}"-->
<!--              color="white"-->
<!--  >-->
<!--    <v-carousel-item-->
<!--        v-for="(item,b) in items"-->
<!--        :key="b"-->
<!--        :src="item.src"-->
<!--        cover-->
<!--        :height="this.picHeight"-->
<!--    ></v-carousel-item>-->
<!--  </v-carousel>-->
</template>

<script>
import FormCallToRentAuto from "@/components/formCallToRentAuto";
export default {
  name: "vCar.vue",
  components: {
    FormCallToRentAuto
  },
  data () {
    return {
      carServiceText: null,
      carTitle: null,
      carPriceTransfer: null,
      carPriceRent: null,
      carAddInfo:null,
      isCarAddInfo:null,
      selected : 'BMW 5 series',
      carouselHeight : "0px",
      picHeight: "100%",
      windowHeight: window.innerHeight,
      heightD : 0,
      // slides: [
      //   {title: 'BMW 5 series 2020 г.в. черный',},
      //   {title: 'BMW 5 series 2020 г.в. белый',},
      //   {title: 'BMW 5 series рестайлинг',},
      //   {title: 'MERCEDES E Class',},
      // ],

      items: [
        {src: require("../assets/img/b5_1.jpg")},
        {src: require("../assets/img/b5_2.jpg")},
        {src: require("../assets/img/b5_3.jpg")},
        {src: require("../assets/img/b5_4.jpg")},
      ],
    }
  },
  methods:{
    getCarouselHeight () {
      if (this.windowHeight > 901 ) {
        this.carouselHeight = "900px"

      }
      if (this.windowHeight < 901) {
        this.carouselHeight = 300
        this.picHeight = 300

      }
    },
    getCarProps(){
      this.carServiceText = this.$parent.carServiceText;
      this.carPriceArr = this.$parent.carPrice;
      this.carTitle = this.carPriceArr[0].title;
      this.carPriceTransfer = this.carPriceArr[0].costTransfer;
      this.carPriceRent = this.carPriceArr[0].costRent;
      this.carAddInfo = this.carPriceArr[0].addInfo;
      if (this.carAddInfo != null) {
        this.isCarAddInfo = true;
      }

    }
  },
  mounted() {

    this.getCarProps();
    this.getCarouselHeight();
    if (this.windowHeight > 901 ) {
      this.carouselHeight = 900
      return this.carouselHeight
    }
    if (this.windowHeight < 901) {
      this.carouselHeight = 300
      return this.carouselHeight
    }
  }
}
</script>

<style scoped>

</style>